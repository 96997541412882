var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"serviceAnalysis-container common-padding",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('breadcrumb',{attrs:{"BreadcrumbData":_vm.BreadcrumbData}}),_c('div',{staticClass:"common-screen-container"},[_c('div',{staticClass:"common-input-container"},[_c('span',[_vm._v("基准值:")]),_c('el-select',{staticClass:"common-screen-input",attrs:{"placeholder":"请选择","filterable":""},on:{"change":_vm.commonFun},model:{value:(_vm.params.referenceType),callback:function ($$v) {_vm.$set(_vm.params, "referenceType", $$v)},expression:"params.referenceType"}},[_c('el-option',{key:"other",attrs:{"label":"客户","value":"other"}}),_c('el-option',{key:"system",attrs:{"label":"系统","value":"system"}})],1)],1),_c('el-button',{staticClass:"common-screen-btn",attrs:{"type":"primary"},on:{"click":function () {
          _vm.satisfactionList();
        }}},[_vm._v("查 询")]),_c('el-button',{staticClass:"common-screen-btn",attrs:{"plain":""},on:{"click":function () { return _vm.resetBtn(); }}},[_vm._v("重 置")]),_c('br'),_c('div',{staticClass:"common-input-container"},[_c('common-lately-date',{ref:"commonReset",attrs:{"commonRadio":_vm.commonRadio,"startDate":_vm.params.startDate,"endDate":_vm.params.endDate},on:{"commonGetDate":_vm.commonGetDate}})],1)],1),_c('el-button',{staticClass:"common-screen-btn",on:{"click":function () { return _vm.download(); }}},[_vm._v("导出数据表格")]),_c('div',{attrs:{"id":"echartsLine"}}),_c('el-table',{staticClass:"common-table",attrs:{"data":_vm.tableData},on:{"sort-change":_vm.tableSort}},[_c('el-table-column',{attrs:{"prop":"area","label":"分公司(地区)","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"shopName","label":"店铺名称","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"platformName","label":"所属平台","sortable":"custom","formatter":_vm.tableColumn,"width":"200"}}),_c('el-table-column',{attrs:{"prop":"consultationNum","label":"总咨询量","formatter":_vm.tableColumn}}),_c('el-table-column',{attrs:{"prop":"piecesNumber","label":"条数（不满、很不满）","formatter":_vm.tableColumn,"sortable":"custom"}}),_c('el-table-column',{attrs:{"label":"满意度","sortable":"custom","prop":"satisfaction"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.satisfactionFlag == '0' && scope.row.satisfaction ? 'warning' : ''},[_vm._v(_vm._s(scope.row.satisfaction || "--"))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"140","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function () {
              _vm.$router.push({
                path: '/data/customer-service-satisfied/source',
                query: {
                  companyId: _vm.params.companyId,
                  shopId: scope.row.shopId,
                  startDate: _vm.params.startDate,
                  commonRadio: _vm.commonRadio,
                  endDate: _vm.params.endDate,
                  referenceType: _vm.params.referenceType,
                },
              });
            }}},[_vm._v("满意度数据源")])]}}])})],1),_c('el-pagination',{staticStyle:{"text-align":"center"},attrs:{"page-sizes":_vm.pagination.pageSizes,"page-size":_vm.params.pageSize,"current-page":_vm.params.page,"layout":"total, prev, pager, next,sizes, jumper","total":_vm.pagination.total},on:{"size-change":function (e) { return _vm.pageFun(e, 'pageSize'); },"current-change":function (e) { return _vm.pageFun(e); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }